
import { useEffect,Fragment, useState } from 'react'
import { Dialog, DialogPanel,TransitionChild,Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import useAuthToken from "../../services/api";
import DatePicker from "react-datepicker";
import Notification, { showNotification } from "../../common/notification/notification";
import { saveAs } from "file-saver";


export default function EditTask({open, setOpen,taskId,onUpdate }) {
    const { callApiWithToken, callApiWithTokenOnly } = useAuthToken();
    const [showLoader, setShowLoader] = useState(false);
    const [query, setQuery] = useState("");
    const [people, setPeople] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [reassignValue, setReassignValue] = useState('');
    const [task, setTask] = useState(null);
    const [duedateReason, setDuedateReason] = useState(null);
    const [updatedDueDate, setUpdatedDueDate] = useState(null);
    const [openUpdateDueDate, setOpenUpdateDueDate] = useState(false);
    const [title,setTitle] = useState('');
    const [description,setDescription] = useState('')
    const [files,setFiles] = useState([])
    const [notification,setNotification]=useState('')
    const [buList, setBUList] = useState([]);
    const [selectedBu, setSelectedBu] = useState('')
    const [creator, setCreator] = useState('');
    const [raisedBy,setRaisedBy] = useState('');
    const [creationDate,setCreationDate] = useState('');
    const [modifiedDate,setModifiedDate] = useState('');
    const [status,setStatus] = useState(Number);
    const [businessId,setBusinessId] = useState('');
    const [uploadingFiles, setUploadingFiles] = useState([]);
    // const [open, setOpen] = useState(true)

    // function to get teams data 
    const getTeamsData = async (event) => {
        let query = event.target.value;
        setQuery(query);
        setReassignValue(query);
        
        if (query.length > 2) {
            try {
                let peopleList = await fetchPeople(query);
                setPeople(peopleList);
            } catch (error) {
                console.error('Error fetching people:', error);
                setPeople([]);
            }
        } else {
            setPeople([]);
        }
        // console.log('people', people);
    };

    const fetchPeople = async (searchQuery) => {
        try {
            setShowLoader(true);
            const response = await callApiWithTokenOnly(`https://graph.microsoft.com/v1.0/search/query`, {
                method: 'POST',
                body: JSON.stringify({
                    "requests": [
                        {
                            "entityTypes": ["person"],
                            "query": {
                                "queryString": searchQuery
                            }
                        }
                    ]
                })
            });

            if (response && response.value && response.value[0] && response.value[0].hitsContainers && response.value[0].hitsContainers[0]) {
                setShowLoader(false);
                const results = response.value[0].hitsContainers[0].hits;

                if (results) {
                    const resources = results.map(result => result.resource);
                    const filteredResources = resources.filter(resource => resource.personType.subclass === "OrganizationUser");
                    return filteredResources;
                }
            }
            setShowLoader(false);
            return [];
        } catch (error) {
            setShowLoader(false);
            console.error('Error fetching and filtering results:', error);
            return [];
        }
    };
    const handleNameClick = (name) => {
        setReassignValue(name);
        setPeople([])
    };
    
    // const refetchTask = async () => {
    //     await fetchTaskDetail(); 

    //     // await onListUpdate()
    // };
     // main method to fetch task details by its ID
    const fetchTaskDetail = async () => {
        // console.log('fetch tasks and edit task')
        
            setShowLoader(true);
            const result = await callApiWithToken(
                `${process.env.REACT_APP_SERVER_URL}task/${taskId}`
            );
            // console.log('result.data',result.data)
            setShowLoader(false);
            if(result.success) {
                let taskData = result.data;
                // console.log('taskData',taskData)
                let updatedDueDate;

                // Check if updatedDueDate is valid
                if (taskData.updatedDueDate && taskData.updatedDueDate.value) {
                    updatedDueDate = new Date(taskData.updatedDueDate.value);
                    // console.log('Updated Due Date: .>', updatedDueDate);
                }
    
    
                setTitle(taskData.title)
                setDescription(taskData.description)
                setFiles(taskData.attachments? taskData.attachments.map(attachment => attachment):files)
                setSelectedBu(taskData.business?taskData.business.businessId:businessId);
                setCreator(taskData.creator)
                setRaisedBy(taskData.assignedBy)
                setReassignValue(taskData.assignedTo);
                setCreationDate(taskData.creationDate)
                setModifiedDate(taskData.modifiedDate)
                setStatus(taskData.status._id)
                if (updatedDueDate) {
                    setUpdatedDueDate(updatedDueDate)
                    setTask({ ...taskData, dueDate: updatedDueDate });
                    setDuedateReason(taskData.updatedDueDate.reason);
                } else {
                    setTask(taskData); // Set taskData directly if no updated due date
                }
                if (!taskData.business) {
                    setBusinessId('');
                }

            } else {
                showNotification(setNotification, "error", "Unauthorised User!"); 
                setOpen(false);
                // navigate(`/tasks`);
            }
            setTask(result.data);
    }

    const updateDueDateReason=(event)=>{
        setDuedateReason(event.target.value)
    }
    const onDateChange = (date) => {
        setUpdatedDueDate(date);
        // console.log('date',date)
        setOpenUpdateDueDate(true)
    };
    const updateTitle = (e)=>{
        setTitle(e.target.value)
    }
    const updateDescription=(e)=>{
        setDescription(e.target.value)
    }
    
    const fileUpload = (e) => {
        // e.preventDefault();
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB in bytes
        const selectedFiles = Array.from(e.target.files);
        let filesExceedingSizeLimit = false;
    
        const validFiles = selectedFiles.filter(file => {
            if (file.size <= maxSizeInBytes) {
                return true;
            } else {
                filesExceedingSizeLimit = true;
            }
        });
    
        if (filesExceedingSizeLimit) {
            showNotification(setNotification, "error", "File size exceeds 10 MB. Please select a smaller file.");
            e.target.value = null;
        }
    
        const newUploadedFiles = validFiles.map(file => ({
            name: file.name,
            url: URL.createObjectURL(file)  // Create a temporary URL for the file
        }));
    
        setUploadingFiles(prevUploadingFiles => [...prevUploadingFiles, ...newUploadedFiles]);
    };
    
    const handleSelectBUChange=(event)=>{
        // console.log('event',event.target.value)
        setSelectedBu(event.target.value)
    }
    // method to update Due Date
    const saveEditedTask = async(event) => {
       
        // console.log('edited',event, files)
      event.preventDefault();
      // Validate required fields
        if (!title) {
            showNotification(setNotification, "error", "Please enter title.");
            return;
        }
        // if (!description ) {
        //     showNotification(setNotification, "error", "Please enter description.");
        //     return;
        //  }
        if (!updatedDueDate ) {
            showNotification(setNotification, "error", "Please enter due date.");
            return;
        }
        if(openUpdateDueDate && !duedateReason){
            showNotification(setNotification, "error", "Please enter due date reason.");   
            return;
        }
        // if (!selectedBu) {
        //     showNotification(setNotification, "error", "Please enter Business unit.");
        //     return;
        // }
        if (!reassignValue ) {
            showNotification(setNotification, "error", "Please assign task to someone.");
            return;
        }
        
   
        const date = new Date();
            const formatDateToDateTimeString = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
        };
        const combinedFiles = [...files, ...uploadingFiles];
        const uniqueFiles = Array.from(new Map(combinedFiles.map(file => [file.name, file])).values());


        let requestBody = {
            updatedDueDate: {value: updatedDueDate.getTime(), 
            reason: duedateReason},
            taskId:taskId,
            title: title,
            description: description,
            dueDate: updatedDueDate.getTime(),
            attachments: uniqueFiles,
            creator: creator,
            assignedTo:reassignValue,
            assignedBy:raisedBy,
            creationDate:creationDate,
            modifiedDate:formatDateToDateTimeString(date),
            isRead: true,
            status: status,
            businessId:selectedBu,
        }
        
        setShowLoader(true);
        const res = await callApiWithToken(
        `${process.env.REACT_APP_SERVER_URL}task/update/${taskId}`,
        {
            method: "PUT",
            body: JSON.stringify(requestBody),
        }
        );
        setShowLoader(false);
        if (res.success && res.data) {
            showNotification(setNotification, "success", "Task updated successfully!");
            setTimeout(() => {
                setOpen(false)
                onUpdate();
            }, 2000);
            
        } 
    }
    const fetchBUList = async () => {
        try {
          const response = await callApiWithToken(
            `${process.env.REACT_APP_SERVER_URL}business/getAll`
          );
          setBUList(response.data);
        //   console.log('BU list', response.data)
        } catch (error) {
          console.error("Error fetching statuses:", error);
        }
        
      };
    // method to download task document
  const downloadFile = (fileName) => {
    const downloadUrl = `${process.env.REACT_APP_SERVER_URL}file/download?key=${fileName}`;
    fetch(downloadUrl, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, fileName);
        // setTimeout(() => {
        //   if (window.location.hash) {
        //     window.history.replaceState(
        //       null,
        //       "",
        //       window.location.pathname + window.location.search
        //     );
        //   }
        // }, 1000);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };
  const removeFile = (fileName) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    setUploadingFiles(prevUploadingFiles => prevUploadingFiles.filter(file => file.name !== fileName));
};

    // console.log('tack',task)
    useEffect(() => {
        if (taskId) {
            fetchTaskDetail();
            fetchBUList();
        }
        // console.log('taskId >>',taskId,title,description, assignedTo,raisedBy, businessId)
    }, [taskId,uploadingFiles])
   

  return (
    <>
    <Transition show={open} appear={true}>
    <Dialog onClose={setOpen} className="relative z-10">
    <Notification type={notification?.type} message={notification?.message} />
    
      <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
      <div className="fixed inset-0 overflow-hidden bg-[rgba(0,0,0,0.3)]">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="h-full overflow-y-scroll bg-[#f9f9fb] shadow-xl">

            <form onSubmit={saveEditedTask}>
                <div className='flex flex-col '>
                <div className="px-0 sm:px-0">
                  <div className="flex p-3 border-color-[#ddd] border-b-[1px] items-center justify-between">
                    <div className="text-base font-semibold leading-6 text-gray-900">
                        Edit Task 
                        
                    </div>
                    <div className="ml-3 flex h-7 items-center">
                    <button type='submit' className="inline-flex mr-4 h-[33px] items-center justify-center rounded-md bg-atom_rojored px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored">
                    <svg className="mr-2" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.25 12.75V8.25H10.75V12.75H12.25V4.37132L10.1287 2.25H1.75V12.75H3.25ZM1 0.75H10.75L13.75 3.75V13.5C13.75 13.9142 13.4142 14.25 13 14.25H1C0.58579 14.25 0.25 13.9142 0.25 13.5V1.5C0.25 1.08579 0.58579 0.75 1 0.75ZM4.75 9.75V12.75H9.25V9.75H4.75Z" fill="white"></path></svg>Save</button>
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="relative rounded-md bg-white text-atom_onxyblack"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-2 sm:px-3">
                   <div className=''>
                            <div className='flex mb-4 relative'>
                                <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"></path></svg>
                                
                                <input type='text' value={reassignValue} onChange={getTeamsData} placeholder='Reassign to*' className="block h-[40px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm text-[12px] sm:leading-6"/>
                                {people.length > 0 &&
                                    <div className="absolute left-[24px] right-0 z-10 mt-1 top-10 max-h-60 overflow-auto rounded-md bg-white py-1 text-[12px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ">
                                        {people.map((item,i)=>(
                                            <div
                                            key={item.id}
                                            className={`cursor-pointer ${i == 0 && 'hover:bg-[#086AC9] hover:text-white'} ${i === 0 && hoveredIndex === null ? 'bg-[#086AC9] text-white' : ''} ${i !== 0 && hoveredIndex === i ? 'bg-[#086AC9] text-white' : ''} px-8 py-2`}
                                            onMouseEnter={() => setHoveredIndex(i)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                            onClick={() => handleNameClick(item.userPrincipalName)}
                                        >
                                            {item.displayName} - {item.jobTitle}
                                            <span className='block'>{item.userPrincipalName}</span>
                                        </div>
                                        ))}
                                        
                                   </div>
                                }
                                
                            </div>
                            <div className='flex mb-4'>
                                <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 14V16H6V14H8ZM18 14V16H10V14H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path></svg>
                                
                                <div className="customDatePicker w-full border-0" onClick={()=>setOpenUpdateDueDate(true)} >
                                    {/* {UtilService.formatTimestampTodate(updatedDueDate)} */}
                                  
                                    <DatePicker dateFormat="dd/MM/yyyy" placeholderText="Select Due Date" selected={updatedDueDate} onChange={onDateChange}/>
                                    
                                    
                                </div>
                               
                            </div>
                            
                            {openUpdateDueDate &&
                                    <div className='ml-6 -mt-2'>
                                        <textarea placeholder="Reason for update*" rows={3} className="block mb-2 w-full placeholder:text-[#3C3C3C] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm text-[12px] sm:leading-6" 
                                        value={duedateReason} onChange={(e) => updateDueDateReason(e)}/>
                                  
                                    </div>
                                }
                                <div className="flex mb-4">
                            <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M11.8611 2.39057C12.8495 1.73163 14.1336 1.71797 15.1358 2.35573L19.291 4.99994H20.9998C21.5521 4.99994 21.9998 5.44766 21.9998 5.99994V14.9999C21.9998 15.5522 21.5521 15.9999 20.9998 15.9999H19.4801C19.5396 16.9472 19.0933 17.9102 18.1955 18.4489L13.1021 21.505C12.4591 21.8907 11.6609 21.8817 11.0314 21.4974C10.3311 22.1167 9.2531 22.1849 8.47104 21.5704L3.33028 17.5312C2.56387 16.9291 2.37006 15.9003 2.76579 15.0847C2.28248 14.7057 2 14.1254 2 13.5109V6C2 5.44772 2.44772 5 3 5H7.94693L11.8611 2.39057ZM4.17264 13.6452L4.86467 13.0397C6.09488 11.9632 7.96042 12.0698 9.06001 13.2794L11.7622 16.2518C12.6317 17.2083 12.7903 18.6135 12.1579 19.739L17.1665 16.7339C17.4479 16.5651 17.5497 16.2276 17.4448 15.9433L13.0177 9.74551C12.769 9.39736 12.3264 9.24598 11.9166 9.36892L9.43135 10.1145C8.37425 10.4316 7.22838 10.1427 6.44799 9.36235L6.15522 9.06958C5.58721 8.50157 5.44032 7.69318 5.67935 7H4V13.5109L4.17264 13.6452ZM14.0621 4.04306C13.728 3.83047 13.3 3.83502 12.9705 4.05467L7.56943 7.65537L7.8622 7.94814C8.12233 8.20827 8.50429 8.30456 8.85666 8.19885L11.3419 7.45327C12.5713 7.08445 13.8992 7.53859 14.6452 8.58303L18.5144 13.9999H19.9998V6.99994H19.291C18.9106 6.99994 18.5381 6.89148 18.2172 6.68727L14.0621 4.04306ZM6.18168 14.5448L4.56593 15.9586L9.70669 19.9978L10.4106 18.7659C10.6256 18.3897 10.5738 17.9178 10.2823 17.5971L7.58013 14.6247C7.2136 14.2215 6.59175 14.186 6.18168 14.5448Z"></path></svg>
                                <select id="bu-select" value={selectedBu} className="block h-[40px] text-[12px] w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm text-[12px] sm:leading-6" onChange={handleSelectBUChange}>
                                            <option value="">Select Business Unit</option>
                                            {buList.map((bu,i) => (
                                            <option key={i} value={bu.businessId}>
                                                {bu.businessName}
                                            </option>
                                            ))}
                                </select>
                            </div>
                            <div className='flex mb-4'>
                                <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M5 18.89H6.41421L15.7279 9.57627L14.3137 8.16206L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21231C16.8256 2.82179 17.4587 2.82179 17.8492 3.21231L20.6777 6.04074C21.0682 6.43126 21.0682 7.06443 20.6777 7.45495L9.24264 18.89H21V20.89ZM15.7279 6.74785L17.1421 8.16206L18.5563 6.74785L17.1421 5.33363L15.7279 6.74785Z"></path></svg>
                                
                                <input type='text' value={title} onChange={(e)=>updateTitle(e)} placeholder='Task Title*' className="h-[40px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm text-[12px] sm:leading-6"/>
                            </div>
                            <div className='flex items-start mb-4'>
                               <svg className='w-[19px] mr-2 mt-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M21 16L14.9968 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V16ZM19 15V4H5V20H14V15H19Z"></path></svg>
                                
                                <textarea placeholder='Description' value={description} onChange={(e)=>updateDescription(e)} className="block w-full h-[100px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm text-[12px] sm:leading-6"/>
                            </div>
                            <div className='flex mb-4 items-start'>
                                <svg className='w-[19px] mt-1 mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M14 13.5V8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8V13.5C6 17.0899 8.91015 20 12.5 20C16.0899 20 19 17.0899 19 13.5V4H21V13.5C21 18.1944 17.1944 22 12.5 22C7.80558 22 4 18.1944 4 13.5V8C4 4.68629 6.68629 2 10 2C13.3137 2 16 4.68629 16 8V13.5C16 15.433 14.433 17 12.5 17C10.567 17 9 15.433 9 13.5V8H11V13.5C11 14.3284 11.6716 15 12.5 15C13.3284 15 14 14.3284 14 13.5Z"></path></svg>
                                
                                <div className="block  items-center text-left pl-1 w-full sm:leading-6">
                                   <div className='rounded-md cursor-pointer min-h-[30px] w-[150px] text-center leading-[30px] relative border-[1px] text-gray-900 shadow-sm placeholder:text-gray-700 sm:text-sm text-[12px] '> 
                                    <span className='cursor-pointer '>Attach New Document</span>
                                    <input type='file' onChange={(e)=>fileUpload(e)} className='absolute inset-0 opacity-0 cursor-pointer' /></div>
                                    <div className='align-middle mt-2'>
                                        {files.length > 0 ? 
                                            <>
                                            <span className='mb-2 block text-[12px] font-bold'>Attached Docs:-</span>
                                            {files.map((file, index) => (
                                            <div key={index} className='flex items-center mb-1'>
                                                <button type="button" onClick={() => downloadFile(file.name)} className='text-xs text-[#288BED] block'>{file.name}</button>
                                             </div>
                                            ))}</>
                                         : (
                                            <span className='text-xs block'>No file selected</span>
                                        )}
                                        {uploadingFiles.length > 0 &&
                                            uploadingFiles
                                                .filter(uploadingFile => !files.some(file => file.name === uploadingFile.name))
                                                .map((file, index) => (
                                                    <div key={index} className='flex items-center mb-1'>
                                                        <span className='text-xs block'>{file.name}</span>
                                                        <button type="button" onClick={() => removeFile(file.name)}>
                                                            <svg className='w-3 ml-1 h-3' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                                <path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ))
                                        }
                                        </div>
                                    
                                </div>
                            </div>
                    </div>
                 </div>   
                </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
      </TransitionChild>
    </Dialog>
    </Transition>
    </>
  )



}
