import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Transition, Dialog,TransitionChild,DialogPanel,
    DialogTitle,ComboboxInput,ComboboxOptions,ComboboxOption } from "@headlessui/react";
import Notification, { showNotification } from "../../common/notification/notification";
import Loader from "../../common/loader/loader";
import { saveAs } from 'file-saver';

import {
    XMarkIcon,
    PhotoIcon,
} from "@heroicons/react/24/outline";
import {
    CheckIcon,
} from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

import DatePicker from "react-datepicker";
import useAuthToken from "../../services/api";
import { useTaskEvent } from "../../contexts/TaskEventContext";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const notificationMethods = [
    { id: 1, title: "Assign by me" },
    { id: 2, title: "Assign on behalf of someone" },
];


function CreateTask({ open, setOpen }) {
    const { callApiWithToken, callApiWithTokenOnly } = useAuthToken();
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [people, setPeople] = useState([]);
    const [assignedTo, setAssignedTo] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [assignedBy, setAssignedBy] = useState('');
    const [dueDate, setDueDate] = useState(null);
    const [selectedNotificationMethod, setSelectedNotificationMethod] = useState(1);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [notification, setNotification] = useState(null);
    const [showLoader, setshowLoader] = useState(false);
    const { triggerFetchTasks } = useTaskEvent();
    const [selectedBu, setSelectedBu] = useState('');
    const [buList, setBuList] = useState([])

    // method to return an object containing any validation errors
    const validateForm = (title, assignedTo, selectedNotificationMethod) => {
        const errors = {}; // Object to store validation errors
      
        // Title validation
        if (!title) {
          errors.title = 'Title is required.';
        } else if (title.length > 255) {
          errors.title = 'Title cannot exceed 255 characters.';
        }
        // Description validation
        if (!description) {
            errors.title = 'Description is required.';
        }
        // Due Date validation
        if (!dueDate) {
            errors.title = 'Due date is required.';
        }
        // Assigned To validation
        if (!assignedTo) {
          errors.assignedTo = 'Assigned to is required.';
        } else if(!assignedTo['userPrincipalName']){
            errors.assignedTo = 'User does not belong to the organisation!';
        }
        // Assigned By validation
        if (selectedNotificationMethod === 2) {
            if(!assignedBy) {
                errors.assignedBy = 'Assigned by is required.';
            }
            else if(!assignedBy['userPrincipalName']) {
                errors.assignedBy = 'Assignee does not belong to the organisation!';
            }
        }
        if(!selectedBu){
            errors.selectedBu = 'BU is required.';
        }
        return errors;
    };          

    // main method to create a task 
    const createTask = async (event) => {
        event.preventDefault();
        const errors = validateForm(title, assignedTo, selectedNotificationMethod);
          // Check if there are any validation errors
        if (Object.keys(errors).length > 0) {
            const errorMessage = Object.values(errors).join(" ");
            showNotification(setNotification, "error", errorMessage);
            console.error('Validation errors:', errors);
            return; // Prevent API call if there are errors
        }
        try {
            setshowLoader(true);
            let requestBody = {
                title: title,
                description: description,
                dueDate: dueDate.getTime(),
                assignedTo: (assignedTo['userPrincipalName']).toLowerCase(),
                assignedBy: selectedNotificationMethod === 1 ? localStorage.getItem("userEmail") : (assignedBy['userPrincipalName']).toLowerCase(),
                assignedByFullName: selectedNotificationMethod === 1 ? localStorage.getItem("userName") : assignedBy['displayName'],
                assignedToFirstName: assignedTo['givenName'],
                businessId:selectedBu
            }
            if(file) {
                requestBody['attachments'] = [{
                    name: file.name,
                    url: downloadUrl
                }]
            };
            const data = await callApiWithToken(`${process.env.REACT_APP_SERVER_URL}task/create`, {
                method: 'POST',
                body: JSON.stringify(requestBody)
            });
            setshowLoader(false);
            setNotification(null);
            // console.log('data',data)
            if(data.success) {
                showNotification(setNotification, "success", data.message);
                // navigate("/tasks");
                setTimeout(() => {
                    setOpen(false);
                    triggerFetchTasks();
                }, 2000);
                
                resetForm();    
            } else {
                showNotification(setNotification, "error", data.message); 
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Reset form values after successful submission
    const resetForm = () => {
        setTitle('');
        setDescription('');
        setAssignedTo(null);
        setAssignedBy(null);
        setDueDate(null); 
        setSelectedBu('')
        setSelectedNotificationMethod(1); // Set default notification method (optional)
        setFile(null);
    }

    

    // method to validate and set file name
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB in bytes

        if (selectedFile.size > maxSizeInBytes) {
          showNotification(setNotification, 'error', 'File size exceeds 10 MB. Please select a smaller file.');
          setFile(null);
          e.target.value = null;
        } else {
          setNotification(null);  
          setFile(selectedFile);
        }
    };

    // method to remove attached file 
    const handleRemoveFile = () => {
        setFile(null);
    };    

    // method to upload file while creating task
    const handleUpload = () => {
        if (file) {
            setshowLoader(true);
            const formData = new FormData();
            formData.append('file', file);

            fetch(`${process.env.REACT_APP_SERVER_URL}file/upload`, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())  // Parse JSON response
            .then(data => {
                setshowLoader(false);
                setDownloadUrl(data.downloadUrl);
                showNotification(setNotification, "success", data.message);
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
            });
    
        }
    };

    // method to download the uploaded file after clicking on file name link
    const downloadFile = (fileName) => {
        const downloadUrl = `${process.env.REACT_APP_SERVER_URL}file/download?key=${fileName}`;
        fetch(downloadUrl, {
          method: 'GET',
        })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, fileName);
        })
        .catch(error => {
          console.error('Error downloading file:', error);
        });      
    };      

    // calling microsoft graph API 
    const fetchPeople = async (searchQuery) => {
        try {
            setshowLoader(true);
            const data = await callApiWithTokenOnly(`https://graph.microsoft.com/v1.0/search/query`,
            {
                method: 'POST',
                body: JSON.stringify({
                    "requests": [
                        {
                            "entityTypes": [
                                "person"
                            ],
                            "query": {
                                "queryString": searchQuery
                            }
                        }
                    ]
                })
            });
            if(data && data.value && data.value[0] && data.value[0].hitsContainers && data.value[0].hitsContainers[0]) {
                setshowLoader(false);
                const results = data.value[0].hitsContainers[0].hits;
                // Map to get resource objects
                if(results) {
                    const resources = results.map(result => result['resource']);
                    // Filter resources by personType.subClass
                    const filteredResources = resources.filter(resource => resource.personType.subclass === "OrganizationUser");
                    return filteredResources;
                }
            }
            
        }
        catch (error) {
            setshowLoader(false);
            console.error('Error fetching and filtering results:', error);
            return [];
        }
    };
    const fetchBUList = async () => {
        try {
          const response = await callApiWithToken(
            `${process.env.REACT_APP_SERVER_URL}business/getAll`
          );
          setBuList(response.data);
        //   console.log('BU list', response.data)
        } catch (error) {
          console.error("Error fetching statuses:", error);
        }
        
      };
    

    const handleSelectBUChange = (event) => {
        setSelectedBu(event.target.value);
    };


    // function to get teams data 
    const getTeamsData = async (event) => {
        let query = event.target.value;
        setQuery(query);
        if (query.length > 2) {
            try {
                let peopleList = await fetchPeople(query);
                setPeople(peopleList);
            } catch (error) {
                console.error('Error fetching people:', error);
                setPeople([]);
            }
        } else {
            setPeople([]);
        }
    };

    // Function to handle radio button change
    const handleRadioButtonChange = (event) => {
        setSelectedNotificationMethod(parseInt(event.target.value));
    };  

    // const handleAssignedByChange = async (event) => {
    //     setAssignedBy(event.target.value);
    //     let value = event.target.value;
    //     if(value.includes("@moglix.com")) {
    //         const response = await fetch(`https://graph.microsoft.com/v1.0/users/${event.target.value}`,
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    //             },
    //         });
    //         const data = await response.json();
    //         setAssignedByFullName(data.displayName);
    //     } else {
    //         setAssignedByFullName("");
    //     }
    // }
    
    const handleDateChange = (date) => {
        setDueDate(date);
    };  
    // method to call upload file API
    useEffect(() => {
        if (file !== null) {
            // console.log('file', file);
            handleUpload();
        }
        fetchBUList();
        // console.log('selected BU',selectedBu)
    }, [file,selectedBu]);  

    return (<>
        <Notification type = {notification?.type} message = {notification?.message} />
        <div className="fixed inset-0 bg-[#00000070] overflow-hidden">
            {showLoader && (
            <div className="fixed h-screen w-screen bg-[#00000024] z-[1000] top-0 left-0 ">
                <div className="h-full w-full flex items-center justify-center">
                <Loader visible={showLoader} />
                </div>
            </div>
            )}
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-8">
                    <TransitionChild
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <DialogPanel className="pointer-events-auto w-[455px]">
                            <form className="flex h-full flex-col bg-white shadow-xl">
                                <div className="flex-1">
                                    <div className="bg-gray-50 p-[10px] ">
                                        <div className="flex items-start justify-between space-x-3">
                                            <div className="space-y-1">
                                                <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                                                    Create Task
                                                </DialogTitle>
                                            </div>
                                            <div className="flex h-7 items-center">
                                                <button onClick={createTask}
                                                    className="inline-flex mr-4 h-[33px] items-center justify-center rounded-md bg-atom_rojored px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored"
                                                >
                                                    <svg className="mr-2"
                                                        width="14"
                                                        height="15"
                                                        viewBox="0 0 14 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3.25 12.75V8.25H10.75V12.75H12.25V4.37132L10.1287 2.25H1.75V12.75H3.25ZM1 0.75H10.75L13.75 3.75V13.5C13.75 13.9142 13.4142 14.25 13 14.25H1C0.58579 14.25 0.25 13.9142 0.25 13.5V1.5C0.25 1.08579 0.58579 0.75 1 0.75ZM4.75 9.75V12.75H9.25V9.75H4.75Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                    Create
                                                </button>
                                                <button
                                                    type="button"
                                                    className="relative text-[#3c3c3c] mr-[10px] hover:text-gray-500"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" sm:divide-gray-200 sm:py-0 overflow-y-scroll modal-body-height">
                                        <div className="px-[12px] my-[15px] flex space-x-3">
                                            {notificationMethods.map((notificationMethod) => (
                                                <div
                                                    key={notificationMethod.id}
                                                    className="flex items-center "
                                                >
                                                    <input
                                                        id={notificationMethod.id}
                                                        name="notification-method"
                                                        type="radio" 
                                                        value={notificationMethod.id} // Set the value of radio button
                                                        checked={selectedNotificationMethod === notificationMethod.id} // Check if this radio button is selected
                                                        onChange={handleRadioButtonChange} // Handle change event
                                
                                                        className="ml-[5px] radioBtn text-[#086AC9] cursor-pointer"
                                                    />
                                                    <label
                                                        htmlFor={notificationMethod.id}
                                                        className="ml-3 cursor-pointer block text-sm sm:text-xs font-medium leading-6 text-gray-900" 
                                                    >
                                                        {notificationMethod.title}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        {/* {(selectedNotificationMethod === 2) && (

                                        <div className=" sm:px-2 sm:py-2 mt-3">
                                            <input
                                                type="text"
                                                placeholder="Assignee email address"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm sm:leading-6"
                                                value={assignedBy}
                                                onChange={(event) => handleAssignedByChange(event)}                                              
                                            />
                                        </div>)} */}

                                        {(selectedNotificationMethod === 2) && (

                                        <div className="px-[10px] flex items-start relative">
                                            <svg className='w-[19px] mr-2 mt-[10px]'  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M21 3C21.5523 3 22 3.44772 22 4V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5V4C2 3.44772 2.44772 3 3 3H21ZM8 15V17H0V15H8ZM5 10V12H0V10H5ZM19.5659 5H4.43414L12 11.8093L19.5659 5Z"></path></svg>
                                            <Combobox as="div" className="w-full" value={assignedBy} onChange={setAssignedBy}>
                                                <div className="relative flex mb-4 ">
                                                
                                                    <ComboboxInput className="w-full text-[13px] rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm sm:leading-6"
                                                        onKeyUp={getTeamsData} onChange = {(event) => setQuery(event.target.value)} defaultValue = "" placeholder="Assignee email address*" displayValue={(assignedBy) => assignedBy ? assignedBy['displayName'] : ''} />
                                                    {people?.length > 0 && (
                                                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {people.map((p) => (
                                                                <ComboboxOption
                                                                    key={p.id}
                                                                    value={p}
                                                                    className={({ active }) =>
                                                                        classNames(
                                                                            "relative cursor-default select-none py-2 pl-8 pr-4",
                                                                            active
                                                                                ? "bg-[#086AC9] text-white"
                                                                                : "text-gray-900"
                                                                        )
                                                                    }
                                                                >
                                                                    {({ active, selected }) => (
                                                                        <>
                                                                            <span
                                                                                className={classNames(
                                                                                    "block truncate",
                                                                                    selected && "font-semibold"
                                                                                )}
                                                                            >
                                                                                {p.displayName} - {p.jobTitle} <br/> {p.userPrincipalName}
                                                                            </span>

                                                                            {selected && (
                                                                                <span
                                                                                    className={classNames(
                                                                                        "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                                                                        active
                                                                                            ? "text-white"
                                                                                            : "text-[#086AC9]"
                                                                                    )}
                                                                                >
                                                                                    <CheckIcon
                                                                                        className="h-5 w-5"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </ComboboxOption>
                                                            ))}
                                                        </ComboboxOptions>
                                                    )}
                                                </div>
                                            </Combobox>
                                        </div>)}


                                        <div className="px-[10px] flex mb-4 relative">
                                        <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"></path></svg>
                                
                                            <Combobox as="div" className="w-full " value={assignedTo} onChange={setAssignedTo}>
                                                <div className="relative ">
                                                    <ComboboxInput className="w-full text-[13px] rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm sm:leading-6"
                                                        onKeyUp={getTeamsData} onChange = {(event) => setQuery(event.target.value)} defaultValue = "" placeholder="Assign to*" displayValue={(assignedTo) => assignedTo ? assignedTo['displayName'] : ''} />
                                                    {people?.length > 0 && (
                                                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {people.map((p) => (
                                                                <ComboboxOption
                                                                    key={p.id}
                                                                    value={p}
                                                                    className={({ active }) =>
                                                                        classNames(
                                                                            "relative cursor-default select-none py-2 pl-8 pr-4",
                                                                            active
                                                                                ? "bg-[#086AC9] text-white"
                                                                                : "text-gray-900"
                                                                        )
                                                                    }
                                                                >
                                                                    {({ active, selected }) => (
                                                                        <>
                                                                            <span
                                                                                className={classNames(
                                                                                    "block truncate",
                                                                                    selected && "font-semibold"
                                                                                )}
                                                                            >
                                                                                {p.displayName} - {p.jobTitle} <br/> {p.userPrincipalName}
                                                                            </span>

                                                                            {selected && (
                                                                                <span
                                                                                    className={classNames(
                                                                                        "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                                                                        active
                                                                                            ? "text-white"
                                                                                            : "text-[#086AC9]"
                                                                                    )}
                                                                                >
                                                                                    <CheckIcon
                                                                                        className="h-5 w-5"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </ComboboxOption>
                                                            ))}
                                                        </ComboboxOptions>
                                                    )}
                                                </div>
                                            </Combobox>
                                        </div>
      
                                        <div className="px-[10px] flex mb-4 relative">
                                        <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 14V16H6V14H8ZM18 14V16H10V14H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path></svg>
                                
                                            <DatePicker className="w-full text-[13px]" dateFormat="dd/MM/yyyy" placeholderText="Select Due Date*" onChange={handleDateChange} selected={dueDate} minDate={new Date()}
                                                // icon={
                                                //     <svg className="top-[2px] right-[6px]" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                //         xmlns="http://www.w3.org/2000/svg">
                                                //         <path d="M6.50002 0.833344V2.50001H11.5V0.833344H13.1667V2.50001H16.5C16.9603 2.50001 17.3334 2.87311 17.3334 3.33334V16.6667C17.3334 17.1269 16.9603 17.5 16.5 17.5H1.50002C1.03979 17.5 0.666687 17.1269 0.666687 16.6667V3.33334C0.666687 2.87311 1.03979 2.50001 1.50002 2.50001H4.83335V0.833344H6.50002ZM15.6667 9.16668H2.33335V15.8333H15.6667V9.16668ZM5.66669 11.6667V13.3333H4.00002V11.6667H5.66669ZM14 11.6667V13.3333H7.33335V11.6667H14ZM4.83335 4.16668H2.33335V7.50001H15.6667V4.16668H13.1667V5.83334H11.5V4.16668H6.50002V5.83334H4.83335V4.16668Z"
                                                //             fill="#3C3C3C"/>
                                                //     </svg>
                                                // }
                                            />
                                        </div>
                                        <div className="px-[10px] flex mb-4 relative">
                                        <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M11.8611 2.39057C12.8495 1.73163 14.1336 1.71797 15.1358 2.35573L19.291 4.99994H20.9998C21.5521 4.99994 21.9998 5.44766 21.9998 5.99994V14.9999C21.9998 15.5522 21.5521 15.9999 20.9998 15.9999H19.4801C19.5396 16.9472 19.0933 17.9102 18.1955 18.4489L13.1021 21.505C12.4591 21.8907 11.6609 21.8817 11.0314 21.4974C10.3311 22.1167 9.2531 22.1849 8.47104 21.5704L3.33028 17.5312C2.56387 16.9291 2.37006 15.9003 2.76579 15.0847C2.28248 14.7057 2 14.1254 2 13.5109V6C2 5.44772 2.44772 5 3 5H7.94693L11.8611 2.39057ZM4.17264 13.6452L4.86467 13.0397C6.09488 11.9632 7.96042 12.0698 9.06001 13.2794L11.7622 16.2518C12.6317 17.2083 12.7903 18.6135 12.1579 19.739L17.1665 16.7339C17.4479 16.5651 17.5497 16.2276 17.4448 15.9433L13.0177 9.74551C12.769 9.39736 12.3264 9.24598 11.9166 9.36892L9.43135 10.1145C8.37425 10.4316 7.22838 10.1427 6.44799 9.36235L6.15522 9.06958C5.58721 8.50157 5.44032 7.69318 5.67935 7H4V13.5109L4.17264 13.6452ZM14.0621 4.04306C13.728 3.83047 13.3 3.83502 12.9705 4.05467L7.56943 7.65537L7.8622 7.94814C8.12233 8.20827 8.50429 8.30456 8.85666 8.19885L11.3419 7.45327C12.5713 7.08445 13.8992 7.53859 14.6452 8.58303L18.5144 13.9999H19.9998V6.99994H19.291C18.9106 6.99994 18.5381 6.89148 18.2172 6.68727L14.0621 4.04306ZM6.18168 14.5448L4.56593 15.9586L9.70669 19.9978L10.4106 18.7659C10.6256 18.3897 10.5738 17.9178 10.2823 17.5971L7.58013 14.6247C7.2136 14.2215 6.59175 14.186 6.18168 14.5448Z"></path></svg>
                            
                                        <select id="bu-select" value={selectedBu} className="block text-[13px] w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm sm:leading-6" onChange={handleSelectBUChange}>
                                            <option value="">Select Business Unit*</option>
                                            {buList.map((bu,i) => (
                                            <option key={i} value={bu.businessId}>
                                                {bu.businessName}
                                            </option>
                                            ))}
                                        </select>
                                        </div>

                                        <div className="px-[10px]  flex mb-4 relative">
                                        <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M5 18.89H6.41421L15.7279 9.57627L14.3137 8.16206L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21231C16.8256 2.82179 17.4587 2.82179 17.8492 3.21231L20.6777 6.04074C21.0682 6.43126 21.0682 7.06443 20.6777 7.45495L9.24264 18.89H21V20.89ZM15.7279 6.74785L17.1421 8.16206L18.5563 6.74785L17.1421 5.33363L15.7279 6.74785Z"></path></svg>
                                
                                            <input
                                                type="text"
                                                placeholder="Task Heading*"
                                                className="block text-[13px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm sm:leading-6"
                                                value={title}
                                                onChange={(event) => setTitle(event.target.value)}
                                            />
                                        </div>
                                        <div className="px-[10px] items-start flex mb-4 relative">
                                        <svg className='w-[19px] mr-2 mt-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M21 16L14.9968 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V16ZM19 15V4H5V20H14V15H19Z"></path></svg>
                               
                                            <textarea
                                                rows={4}
                                                name="comment"
                                                id="comment"
                                                placeholder="Description*"
                                                className="block text-[13px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#086AC9] sm:text-sm sm:leading-6"
                                                value={description}
                                                onChange={(event) => setDescription(event.target.value)}
                                            />
                                        </div>
                                        <div className="px-[10px] items-start flex mb-4 relative">
                                        <svg className='w-[19px] mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M14 13.5V8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8V13.5C6 17.0899 8.91015 20 12.5 20C16.0899 20 19 17.0899 19 13.5V4H21V13.5C21 18.1944 17.1944 22 12.5 22C7.80558 22 4 18.1944 4 13.5V8C4 4.68629 6.68629 2 10 2C13.3137 2 16 4.68629 16 8V13.5C16 15.433 14.433 17 12.5 17C10.567 17 9 15.433 9 13.5V8H11V13.5C11 14.3284 11.6716 15 12.5 15C13.3284 15 14 14.3284 14 13.5Z"></path></svg>
                                
                                            <div className="w-full cursor-pointer relative flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                <div className="mx-auto justify-center">
                                                    <PhotoIcon
                                                        className="mx-auto h-12 w-12 text-gray-300"
                                                        aria-hidden="true"
                                                    />
                                                    { file == null && (<div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                        <label
                                                            htmlFor="file-upload2"
                                                            className="cursor-pointer rounded-md bg-white font-semibold text-[#086AC9] hover:text-[#086AC9]"
                                                        >
                                                            <span>Attach Document</span>
                                                            <input
                                                                id="file-upload2"
                                                                name="file-upload"
                                                                type="file"
                                                                className="left-0 absolute top-0 cursor-pointer opacity-0 w-full h-full"
                                                                onChange={handleFileChange}
                                                            />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>)}
                                                    {
                                                        file && (
                                                            <div className="flex items-center justify-center" >
                                                           
                                                                <button className = "cursor-pointer" onClick={() => downloadFile(file.name)}><span>{file.name}</span></button>
                                                                <span className="h-6 w-6 px-[5px]" onClick={handleRemoveFile}>
                                                                    <svg className="h-6 w-6 text-gray-600 hover:text-red-500 cursor-pointer" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                    <p className="text-xs leading-5 text-center text-gray-600">
                                                        PNG, JPG, GIF, PDF and others up to 10MB
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </div>
        </>
    )
}

export default CreateTask;