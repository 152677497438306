import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Dialog, DialogPanel } from "@headlessui/react";

import Loader from "../../common/loader/loader";
import Notification, {
  showNotification,
} from "../../common/notification/notification";
import UtilService from "../../services/utilService";
import UpdateDueDate from "./updateDueDate";
import History from "./history";
import useAuthToken from "../../services/api";
import { saveAs } from "file-saver";
import EditTask from "./editTask";


function TaskDetail({
  openDesktop,
  setOpenDesktop,
  openMobile,
  setOpenMobile,
  taskId,
  setTaskId,
  selectedTab,
  onListUpdate,
}) {
  const { callApiWithToken } = useAuthToken();
  // const emailId = localStorage.getItem("userEmail");
  const userName = localStorage.getItem("userName");
  const [showLoader, setshowLoader] = useState(false);
  const [task, setTask] = useState(null);
  const [notification, setNotification] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const navigate = useNavigate();
  const [openUpdateDueDate, setopenUpdateDueDate] = useState(false);
  const [expandComments, setExpandComments] = useState(false);
  const [openHistory, setopenHistory] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [file, setFile] = useState(null);
  const textAreaRef = useRef(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedDetailTab, setSelectedDetailTab] = useState("Detail");
  // let [isOpen, setIsOpen] = useState(false)
  // console.log('openMobile,',openMobile,openDesktop)

  const handleTabClick = (tab) => {
    setSelectedDetailTab(tab);
  };

  const refetchTask = async () => {
    await fetchTaskDetail();
    await onListUpdate();
  };

  const toggleComments = () => {
    setExpandComments(!expandComments);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // main method to fetch task details by its ID
  const fetchTaskDetail = async () => {
    try {
      setshowLoader(true);
      const result = await callApiWithToken(
        `${process.env.REACT_APP_SERVER_URL}task/${taskId}`
      );
      setshowLoader(false);
      if (result.success) {
        let newDueDate = result.data.updatedDueDate
          ? result.data.updatedDueDate.value
          : null;
        if (newDueDate) {
          setTask({ ...result.data, dueDate: newDueDate });
        } else {
          setTask(result.data);
        }
        // navigate(`/tasks?id=${taskId}`)
      } else {
        showNotification(setNotification, "error", "Unauthorised User!");
        setOpenDesktop(false);
        navigate(`/tasks`);
      }
    } catch (err) {
      setshowLoader(false);
      showNotification(setNotification, "error", "Not found!");
    }
  };

  // method to fetch task details whenever selected task changes
  useEffect(() => {
    fetchTaskDetail();
    getCommentsList();
  }, [taskId]);

  // close task detail modal
  const handleCloseTaskDetail = () => {
    setTaskId(null);
    navigate("/tasks");
  };

  // method to sed reminder mail
  const sendReminder = async () => {
    if (task) {
      setshowLoader(true);
      let requestBody = {
        taskId: task.taskId,
        assignedTo: task.assignedTo,
        assignedBy: task.assignedBy,
      };
      const result = await callApiWithToken(
        `${process.env.REACT_APP_SERVER_URL}task/reminder/send`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
        }
      );
      setshowLoader(false);
      if (result.success) {
        showNotification(setNotification, "success", result.message);
      }
    }
  };

  // method to add new comment
  const addNewComment = async () => {
    if(newComment.trim() !== '') {
      let requestBody = {
        taskId: taskId,
        content: newComment,
        userName: userName,
      };
      if (file) {
        requestBody["attachment"] = {
          name: file.name,
          url: downloadUrl,
        };
      }
      const res = await callApiWithToken(
        `${process.env.REACT_APP_SERVER_URL}task/comments/add`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
        }
      );
      if (res.success && res.data) {
        setComments((prevComments) => [...prevComments, res.data]);
        showNotification(setNotification, "success", "Comments added.")
        setNewComment("");
        setFile(null);
        setDownloadUrl(null);
      }
    }else{
      showNotification(setNotification, "error", "Add some comment.")
    }
  };

  // method to fetch comments
  const getCommentsList = async () => {
    const res = await callApiWithToken(
      `${process.env.REACT_APP_SERVER_URL}task/comments/${taskId}/get`
    );
    if (res.success && res.data) {
      setComments(res.data);
    }
  };

  // method to validate and set file name
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB in bytes

    if (selectedFile.size > maxSizeInBytes) {
      showNotification(
        setNotification,
        "error",
        "File size exceeds 10 MB. Please select a smaller file."
      );
      setFile(null);
    } else {
      setNotification(null);
      setFile(selectedFile);
    }
  };

  // method to call upload file API
  useEffect(() => {
    // if (textAreaRef.current) {
    //     textAreaRef.current.style.height = 'auto';
    //     textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    // }
    if (file !== null) {
      // console.log('file', file);
      handleUpload();
    }
  }, [file]);

  // method to upload file in comment
  const handleUpload = () => {
    if (file) {
      setshowLoader(true);
      const formData = new FormData();
      formData.append("file", file);

      fetch(`${process.env.REACT_APP_SERVER_URL}file/upload`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json()) // Parse JSON response
        .then((data) => {
          setshowLoader(false);
          setDownloadUrl(data.data);
          showNotification(setNotification, "success", data.message);
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    }
  };

  // method to remove file
  const handleRemoveFile = () => {
    setFile(null);
  };

  // method to download task document
  const downloadFile = (fileName) => {
    const downloadUrl = `${process.env.REACT_APP_SERVER_URL}file/download?key=${fileName}`;
    fetch(downloadUrl, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, fileName);
        setTimeout(() => {
          if (window.location.hash) {
            window.history.replaceState(
              null,
              "",
              window.location.pathname + window.location.search
            );
          }
        }, 1000);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };


  const onClose=()=> {
    setOpenMobile(false)
  }

  return (
    <>
     <Notification type={notification?.type} message={notification?.message} />
        { openEdit && 
        <EditTask open={openEdit} setOpen={setOpenEdit} onUpdate={refetchTask} taskId = {taskId}/> }
      {showLoader && (
        <div className="fixed h-screen w-screen bg-[#00000024] z-[1000] top-0 left-0 ">
          <div className="h-full w-full flex items-center justify-center">
            <Loader visible={showLoader} />
          </div>
        </div>
      )}
      
      {openDesktop &&
      <div
        className={classNames(
          openDesktop ? "w-2/5" : "w-0",
          "border border-[#C4C4C4] sm:hidden box-border  ml-[5px] mr-[10px] relative task-detail-height mb-2"
        )}
      >
        {task && (
          <div className="relative bg-white h-full">
            <div className="p-2.5 border-b border-[#C4C4C4]">
              <div className="flex items-start justify-between ">
                <div className="flex">
                  <button
                    type="button"
                    className={`mr-3 rounded px-2.5 py-1.5 text-[12px] font-normal shadow-sm text-[#3C3C3C] ${
                      UtilService.getDueDateText(task.dueDate)?.class === "past"
                        ? "bg-red-50"
                        : UtilService.getDueDateText(task.dueDate)?.class ===
                          "present"
                        ? "bg-[#F0FFF0]"
                        : ""
                    }`}
                    onClick={() => setopenUpdateDueDate(true)}
                  >
                    {UtilService.formatTimestampTodate(task?.dueDate)}{" "}
                    <span
                      className={`${
                        UtilService.getDueDateText(task.dueDate)?.class ===
                        "past"
                          ? "text-red-500"
                          : UtilService.getDueDateText(task.dueDate)?.class ===
                            "present"
                          ? "text-[#0A7205]"
                          : ""
                      } font-bold`}
                    >
                      {UtilService.getDueDateText(task?.dueDate)?.text}
                    </span>
                  </button>

                  {/* Update Due Date Button start*/}
                  {/* {parseInt(selectedTab) === 0 && ( */}
                    <button
                      type="button"
                      className=" rounded border border-[#D9232D] text-[#D9232D] mr-1 inline-flex items-center px-1.5 py-1.5 text-[12px] font-normal  shadow-sm "
                      onClick={() => setopenUpdateDueDate(true)}
                    >
                      <span className="mr-2">Update Due Date</span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 0.75V2.25H10.25V0.75H11.75V2.25H14.75C15.1642 2.25 15.5 2.58579 15.5 3V15C15.5 15.4142 15.1642 15.75 14.75 15.75H1.25C0.83579 15.75 0.5 15.4142 0.5 15V3C0.5 2.58579 0.83579 2.25 1.25 2.25H4.25V0.75H5.75ZM14 8.25H2V14.25H14V8.25ZM5 10.5V12H3.5V10.5H5ZM12.5 10.5V12H6.5V10.5H12.5ZM4.25 3.75H2V6.75H14V3.75H11.75V5.25H10.25V3.75H5.75V5.25H4.25V3.75Z"
                          fill="#D9232D"
                        />
                      </svg>
                    </button>
                  {/* )} */}
                  {/* Update Due Date Button end*/}

                  {/* Update Due Date popup start*/}
                  {openUpdateDueDate && (
                    <UpdateDueDate
                      open={openUpdateDueDate}
                      setOpen={setopenUpdateDueDate}
                      taskId={taskId}
                      onUpdate={refetchTask}
                    />
                  )}
                  {/* Update Due Date popup end*/}

                  {/* Set Reminder Button  start*/}
                  {parseInt(selectedTab) === 1 && (
                    <button
                      type="button"
                      className="rounded border border-[#D9232D] text-[#D9232D] inline-flex items-center px-1.5 py-1.5 text-[12px] font-normal  shadow-sm "
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2948 1.21768L11.2049 15.5324C11.0917 15.9287 10.8482 15.9467 10.667 15.5844L7.24982 8.74997L0.441994 6.02686C0.0597338 5.87396 0.0644663 5.64514 0.467531 5.51078L14.7822 0.739229C15.1785 0.607109 15.4058 0.828966 15.2948 1.21768ZM13.2763 2.82232L4.10898 5.87811L8.33642 7.5691L10.6169 12.1301L13.2763 2.82232Z"
                          fill="#D9232D"
                        />
                      </svg>
                      <span className="ml-2" onClick={() => sendReminder()}>
                        Send Reminder
                      </span>
                    </button>
                  )}
                  {/* Set Reminder Button  end*/}
                </div>
                <div className="ml-3 flex h-7 items-center">
                <button type="button" className="mr-3" onClick={() =>setOpenEdit(true)}>
                    <svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M6.41421 15.89L16.5563 5.74785L15.1421 4.33363L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6473L14.435 2.21231C14.8256 1.82179 15.4587 1.82179 15.8492 2.21231L18.6777 5.04074C19.0682 5.43126 19.0682 6.06443 18.6777 6.45495L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z"></path></svg>
                </button>
                  <button
                    type="button"
                    className="mr-2 relative rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={() => setopenHistory(true)}
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <svg className="text-base"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.50008 0.666672C13.1024 0.666672 16.8334 4.39763 16.8334 9.00001C16.8334 13.6023 13.1024 17.3333 8.50008 17.3333C3.89771 17.3333 0.166748 13.6023 0.166748 9.00001H1.83341C1.83341 12.6819 4.81818 15.6667 8.50008 15.6667C12.182 15.6667 15.1667 12.6819 15.1667 9.00001C15.1667 5.31811 12.182 2.33334 8.50008 2.33334C6.2086 2.33334 4.18714 3.48945 2.98717 5.25021L5.16675 5.25001V6.91667H0.166748V1.91667H1.83341L1.83332 3.99932C3.3537 1.97563 5.77402 0.666672 8.50008 0.666672ZM9.33342 4.83334L9.33325 8.65417L12.0356 11.357L10.8571 12.5355L7.66658 9.34417L7.66675 4.83334H9.33342Z"
                              fill="#3C3C3C"
                            />
                          </svg>
                  </button>
                  {openHistory && (
                    <History
                      open={openHistory}
                      setOpen={setopenHistory}
                      taskId={taskId}
                    />
                  )}
                 
                  <button
                    type="button"
                    className="relative rounded-md bg-white text-[#3C3C3C]"
                    onClick={() => {
                      setOpenDesktop(false);
                      handleCloseTaskDetail();
                    }}
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon
                      className="h-[24px] w-[24px] font-sm"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="hforDetailBox">
              <div
                className={`py-2.5 px-6 task-detail-box-height-${
                  expandComments ? "0" : "1"
                } overflow-auto`}
                style={{ display: expandComments ? "none" : "block" }}
              >
                <h1 className="text-base font-medium"> {task?.title}</h1>
                <div className="pl-4 mt-3 text-sm font-normal list-disc">
                  {task?.description}
                </div>
                {task?.attachments && task?.attachments?.length > 0 && (
                <div className="flex items-start mt-2">

                  <span className="text-sm mt-1">Documents : </span>
                  <div className="ml-1">
                  {task?.attachments.map((file,i)=>(
                    <div key={i} className="block">
                      <button
                        className="text-[#288BED] font-medium text-[12px] cursor-pointer"
                        onClick={() => downloadFile(file.name)}
                      >
                        {file?.name}
                      </button>
                    </div>
                    ))}
                    
                  </div>
                </div>)}
              </div>
              <div
                className="[box-shadow:0px_0px_20px_0px_#0000001A] p-5 chat-box-section-height-1 overflow-hidden shadow"
                style={{
                  height: expandComments
                    ? "calc(100% - 55px)"
                    : "calc(48% - 0px)",
                }}
              >
                <div className="flex justify-between">
                  <h2>Comment ({comments?.length})</h2>
                  <button className="flex cursor-pointer" onClick={toggleComments}>
                    {!expandComments && (
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 0V2H2V6H0V0H6ZM0 18V12H2V16H6V18H0ZM20 18H14V16H18V12H20V18ZM20 6H18V2H14V0H20V6Z"
                          fill="#3C3C3C"
                        />
                      </svg>
                    )}
                    {expandComments && (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 7H22V9H16V3H18V7ZM8 9H2V7H6V3H8V9ZM18 17V21H16V15H22V17H18ZM8 15V21H6V17H2V15H8Z"
                          fill="#3C3C3C"
                        />
                      </svg>
                    )}
                  </button>
                </div>

                <div className="chat-box-height overflow-auto mt-3">
                  {comments && comments.length > 0 && (
                    <div>
                      {comments.map((comment) => (
                        <div
                          key={comment._id}
                          className="flex space-x-4 text-sm text-gray-500 w-full "
                        >
                          <div className="border-t border-gray-200 py-2.5 w-full">
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="relative top-[4px] flex items-center justify-center rounded-full bg-[#3C3C3C] text-white text-xs w-[30px] h-[30px]  mr-3">
                                  <span className="absolute -inset-1.5" />

                                  {UtilService.convertToAbbreviation(
                                    comment.userName
                                  )}
                                </div>

                                <h3 className="font-medium text-gray-900">
                                  {comment.userName}
                                </h3>
                              </div>

                              <p>
                                <time
                                  dateTime={UtilService.formatDateToDateTimeString(
                                    comment.createdAt
                                  )}
                                >
                                  {UtilService.formatDateToDateTimeString(
                                    comment.createdAt
                                  )}
                                </time>
                              </p>
                            </div>

                            <div
                              className="prose prose-sm max-w-none text-gray-500 ml-12 mb-1"
                              dangerouslySetInnerHTML={{
                                __html: comment.content,
                              }}
                            />
                            {comment?.attachment && (
                              <span className="ml-12">
                                Attachment:{" "}
                                <button
                                  className="text-[#288BED] text-sm cursor-pointer"
                                  onClick={() =>
                                    downloadFile(comment?.attachment?.name)
                                  }
                                >
                                  <span className="mr-1">
                                    {comment?.attachment?.name}
                                  </span>
                                </button>
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="mt-3 flex rounded-md shadow-sm">
                  <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    {file && (
                      <div className="absolute left-[6px] border-t-1 border-gray-300 top-[1px] input-file-width bg-white rounded-tl-md flex items-center  px-[0px] py-[5px]">
                        <div className="bg-[#e4f2ff] flex items-center">
                          <button
                            className="cursor-pointer leading-[13px]  ml-[2px]"
                            onClick={() => downloadFile(file.name)}
                          >
                            <span className="mr-1 text-[#288BED]  text-sm">
                              {file.name}
                            </span>
                          </button>
                          <button
                            type="button"
                            className="text-gray-600 px-[0px] py-[5px] text-[18px] h-[18px] flex items-center hover:bg-[#c1e1fe]"
                            onClick={handleRemoveFile}
                          >
                            &times;
                          </button>
                        </div>
                      </div>
                    )}
                    <textarea
                      ref={textAreaRef}
                      name="comment"
                      id="comment"
                      className={classNames(
                        file
                          ? "h-[60px] pt-[30px]"
                          : "h-[40px] pt-[8px] pr-[37px]",
                        "block w-full rounded-l-md  py-1.5 px-2 resize-none text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      )}
                      placeholder="Add a comment"
                      value={newComment}
                      onChange={(event) => setNewComment(event.target.value)}
                    />
                    {/* <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full h-[40px] rounded-none rounded-l-md border-0 py-1.5  pr-[30px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                    placeholder="Add a comment"
                    value = {newComment}
                    onChange = {(event) => setNewComment(event.target.value)}
                    /> */}
                    {!file && (
                      <label
                        htmlFor="file-upload"
                        className="absolute right-[24px] top-[8px] cursor-pointer"
                      >
                        <span>
                          <svg
                            width="16"
                            height="19"
                            viewBox="0 0 16 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.33317 11.9999V8.33325C9.33317 6.86049 8.13924 5.66659 6.6665 5.66659C5.19374 5.66659 3.99984 6.86049 3.99984 8.33325V11.9999C3.99984 14.3932 5.93994 16.3333 8.33317 16.3333C10.7264 16.3333 12.6665 14.3932 12.6665 11.9999V5.66659H13.9998V11.9999C13.9998 15.1295 11.4628 17.6666 8.33317 17.6666C5.20356 17.6666 2.6665 15.1295 2.6665 11.9999V8.33325C2.6665 6.12411 4.45736 4.33325 6.6665 4.33325C8.87564 4.33325 10.6665 6.12411 10.6665 8.33325V11.9999C10.6665 13.2886 9.62184 14.3333 8.33317 14.3333C7.0445 14.3333 5.99984 13.2886 5.99984 11.9999V8.33325H7.33317V11.9999C7.33317 12.5522 7.7809 12.9999 8.33317 12.9999C8.88544 12.9999 9.33317 12.5522 9.33317 11.9999Z"
                              fill="#3C3C3C"
                            />
                          </svg>
                        </span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleFileChange}
                        />
                      </label>
                    )}
                  </div>
                  <button
                    type="button"
                    className={classNames(
                        file ? "h-[60px]" : "h-[40px]",
                        "relative w-[40px] bg-[#F0F7FF] -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    )}                   
                    onClick = {() => addNewComment()}
                  >
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.916667 0.121307C0.986875 0.121307 1.05595 0.139049 1.11747 0.172882L16.5028 8.63482C16.7045 8.74574 16.7781 8.99907 16.6672 9.20074C16.6291 9.26999 16.5721 9.32699 16.5028 9.36507L1.11747 17.827C0.915833 17.9379 0.662475 17.8643 0.551575 17.6627C0.517742 17.6012 0.5 17.5322 0.5 17.4619V0.537974C0.5 0.307857 0.68655 0.121307 0.916667 0.121307ZM2.16667 2.65207V8.16657H6.33333V9.83324H2.16667V15.3478L13.7083 8.99991L2.16667 2.65207Z"
                        fill="#3C3C3C"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        
      </div>
}

      {/* Mobile view  */}

     
      {openMobile &&
      <Dialog open={openMobile} as="div" className="relative z-10 focus:outline-none" onClose={setOpenMobile}>
        <div className="fixed z-10 bg-[rgba(0,0,0,0.4)] inset-0"></div>
        <div className="fixed z-[11] inset-0  w-screen overflow-y-auto">
          <div className="flex items-center justify-center ">
            <DialogPanel
              transition
              className="w-full max-w-md rounded-xl top-[200px] bottom-0 fixed bg-white duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <div className="overflow-y-scroll rounded-t-[0.75rem]">
                     
                      <div className="flex border-b rounded-t-[0.75rem] border-gray-300 h-[45px]">
                        <button type="button"
                          onClick={() => handleTabClick("Detail")}
                          className={`px-4 py-2 w-[40%] text-sm font-medium ${
                            selectedDetailTab === "Detail"
                              ? "bg-atom_onxyblack text-white font-medium [box-shadow:0px_3px_6px_0px_#00000000]"
                              : "text-gray-500"
                          }`}
                        >
                          Detail
                        </button>
                        <button type="button"
                          onClick={() => handleTabClick("Chat")}
                          className={`px-4 py-2 w-[40%] text-sm font-medium ${
                            selectedDetailTab === "Chat"
                              ? "bg-atom_onxyblack text-white font-medium [box-shadow:0px_3px_6px_0px_#00000000]"
                              : "text-gray-500"
                          }`}
                        >
                          Chat
                        </button>
                        <button
                          type="button"
                          className="relative rounded-md bg-white text-[#3C3C3C] text-center items-center ml-auto w-[20%]"
                          onClick={() => {
                            onClose(false);
                          }}
                        >
                          
                          <XMarkIcon
                            className="h-[24px] mx-auto block w-[24px] font-sm"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                            {selectedDetailTab === "Detail" && (
                                task && (
                                       <> <div className="flex bg-white py-3 px-3 border-b border-[#C4C4C4] justify-between ">
                                          <button
                                            type="button"
                                            className={`mr-3 px-1 rounded py-1.5 text-sm font-normal shadow-sm text-[#3C3C3C] ${
                                              UtilService.getDueDateText(
                                                task.dueDate
                                              )?.class === "past"
                                                ? "bg-red-50"
                                                : UtilService.getDueDateText(
                                                    task.dueDate
                                                  )?.class === "present"
                                                ? "bg-[#F0FFF0]"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              setopenUpdateDueDate(true)
                                            }
                                          >
                                              {UtilService.formatTimestampTodate(
                                                task?.dueDate
                                              )}{" "}
                                            <span
                                              className={`${
                                                UtilService.getDueDateText(
                                                  task.dueDate
                                                )?.class === "past"
                                                  ? "text-red-500"
                                                  : UtilService.getDueDateText(
                                                      task.dueDate
                                                    )?.class === "present"
                                                  ? "text-[#0A7205]"
                                                  : ""
                                              } font-bold`}
                                            >
                                              {
                                                UtilService.getDueDateText(
                                                  task?.dueDate
                                                )?.text
                                              }
                                            </span>
                                          </button>
                                          <button type="button" className="mr-1" onClick={() =>setOpenEdit(true)}>
                                              <svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M6.41421 15.89L16.5563 5.74785L15.1421 4.33363L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6473L14.435 2.21231C14.8256 1.82179 15.4587 1.82179 15.8492 2.21231L18.6777 5.04074C19.0682 5.43126 19.0682 6.06443 18.6777 6.45495L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z"></path></svg>
                                          </button>
                                          
                                          <button
                                            type="button"
                                            className=" rounded border border-[#D9232D] text-[#D9232D] flex items-center px-2.5 py-1.5 text-sm font-normal  shadow-sm "
                                            onClick={() =>
                                              setopenUpdateDueDate(true)
                                            }
                                          >
                                            <span className="mr-2">
                                              Update Due Date
                                            </span>
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M5.75 0.75V2.25H10.25V0.75H11.75V2.25H14.75C15.1642 2.25 15.5 2.58579 15.5 3V15C15.5 15.4142 15.1642 15.75 14.75 15.75H1.25C0.83579 15.75 0.5 15.4142 0.5 15V3C0.5 2.58579 0.83579 2.25 1.25 2.25H4.25V0.75H5.75ZM14 8.25H2V14.25H14V8.25ZM5 10.5V12H3.5V10.5H5ZM12.5 10.5V12H6.5V10.5H12.5ZM4.25 3.75H2V6.75H14V3.75H11.75V5.25H10.25V3.75H5.75V5.25H4.25V3.75Z"
                                                fill="#D9232D"
                                              />
                                            </svg>
                                          </button>
                                          
                                          {openUpdateDueDate && (
                                            <UpdateDueDate
                                              open={openUpdateDueDate}
                                              setOpen={setopenUpdateDueDate}
                                              taskId={taskId}
                                              onUpdate={refetchTask}
                                            />
                                          )}
                                         
                                          {parseInt(selectedTab) === 1 && (
                                            <button
                                              type="button"
                                              className="rounded border border-[#D9232D] text-[#D9232D] inline-flex items-center px-2.5 py-1.5 text-sm font-normal  shadow-sm "
                                            >
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M15.2948 1.21768L11.2049 15.5324C11.0917 15.9287 10.8482 15.9467 10.667 15.5844L7.24982 8.74997L0.441994 6.02686C0.0597338 5.87396 0.0644663 5.64514 0.467531 5.51078L14.7822 0.739229C15.1785 0.607109 15.4058 0.828966 15.2948 1.21768ZM13.2763 2.82232L4.10898 5.87811L8.33642 7.5691L10.6169 12.1301L13.2763 2.82232Z"
                                                  fill="#D9232D"
                                                />
                                              </svg>
                                              <span
                                                className="ml-2"
                                                onClick={() => sendReminder()}
                                              >
                                                Send Reminder
                                              </span>
                                            </button>
                                          )}
                                         
                                        </div>
                                       
                                    <div className="h-full">
                                      <div
                                        className={`py-2.5 px-3  overflow-auto`}
                                        style={{
                                          display: expandComments
                                            ? "none"
                                            : "block",
                                        }}
                                      >
                                        <h2 className="text-base font-medium">
                                          {" "}
                                          {task?.title}
                                        </h2>
                                        <div className="mt-3 text-sm font-normal list-disc">
                                          {task?.description}
                                        </div>
                                        <div className="flex items-center mt-2">
                                          {task?.attachments &&
                                            task?.attachments?.length > 0 && (
                                              <>
                                                <span className="text-sm flex mr-3">
                                                  Document
                                                </span>
                                                <button
                                                  className="text-[#288BED] font-bold text-sm cursor-pointer"
                                                  onClick={() =>
                                                    downloadFile(
                                                      task?.attachments[0]?.name
                                                    )
                                                  }
                                                >
                                                  {task?.attachments[0]?.name}
                                                </button>
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </div></>
                                )
                            )}
                            {selectedDetailTab === "Chat" && (
                              <div
                                className="[box-shadow:0px_0px_20px_0px_#0000001A] py-2.5 px-3 overflow-hidden shadow"
                                style={{
                                  height: expandComments
                                    ? "calc(100% - 55px)"
                                    : "calc(48% - 0px)",
                                }}
                              >
                                  <h2>Comment ({comments?.length})</h2>

                                <div className="overflow-auto mt-3">
                                  {comments && comments.length > 0 && (
                                      comments.map((comment) => (
                                        <div
                                          key={comment._id}
                                          className="text-sm text-gray-500 "
                                        >
                                          <div className="border-t border-gray-200 py-2.5 ">
                                            <div className="flex justify-between">
                                              <div className="flex">
                                                <div className="relative text-center leading-[30px] rounded-full bg-[#3C3C3C] text-white text-xs w-[30px] h-[30px]  mr-3">
                                                  <span className="absolute -inset-1.5" />

                                                  {UtilService.convertToAbbreviation(
                                                    comment.userName
                                                  )}
                                                </div>

                                                <h3 className="font-medium text-gray-900">
                                                  {comment.userName}
                                                </h3>
                                              </div>

                                              <p>
                                                <time
                                                  dateTime={UtilService.formatDateToDateTimeString(
                                                    comment.createdAt
                                                  )}
                                                >
                                                  {UtilService.formatDateToDateTimeString(
                                                    comment.createdAt
                                                  )}
                                                </time>
                                              </p>
                                            </div>

                                            <div
                                              className="max-w-none text-gray-500 ml-12 mb-1"
                                              dangerouslySetInnerHTML={{
                                                __html: comment.content,
                                              }}
                                            />
                                            {comment?.attachment && (
                                              <span className="ml-12">
                                                Attachment:{" "}
                                                <button
                                                  className="text-[#288BED] text-sm cursor-pointer"
                                                  onClick={() =>
                                                    downloadFile(
                                                      comment?.attachment?.name
                                                    )
                                                  }
                                                >
                                                  <span className="mr-1">
                                                    {comment?.attachment?.name}
                                                  </span>
                                                </button>
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      ))
                                  )}
                                </div>

                                <div className="mt-3 flex w-full rounded-md shadow-sm">
                                  <div className="relative flex w-[90%] focus-within:z-10">
                                    {file && (
                                      <div className="absolute left-[6px] border-t-1 border-gray-300 top-[1px] input-file-width bg-white rounded-tl-md flex items-center  px-[0px] py-[5px]">
                                        <div className="bg-[#e4f2ff] flex items-center">
                                          <button
                                            className="cursor-pointer leading-[13px]  ml-[2px]"
                                            onClick={() =>
                                              downloadFile(file.name)
                                            }
                                          >
                                            <span className="mr-1 text-[#288BED]  text-sm">
                                              {file.name}
                                            </span>
                                          </button>
                                          <button
                                            type="button"
                                            className="text-gray-600 px-[0px] py-[5px] text-[18px] h-[18px] flex items-center hover:bg-[#c1e1fe]"
                                            onClick={handleRemoveFile}
                                          >
                                            &times;
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                    <textarea
                                      ref={textAreaRef}
                                      name="comment"
                                      id="comment"
                                      className={classNames(
                                        file
                                          ? "h-[60px] pt-[30px]"
                                          : "h-[40px] pt-[8px] pr-[37px]",
                                        "block w-full rounded-l-md py-1.5 px-2 resize-none text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                      )}
                                      placeholder="Add a comment"
                                      value={newComment}
                                      onChange={(event) =>
                                        setNewComment(event.target.value)
                                      }
                                    />
                                    {!file && (
                                      <label
                                        htmlFor="file-upload"
                                        className="absolute right-[24px] top-[8px] cursor-pointer"
                                      >
                                        <span>
                                          <svg
                                            width="16"
                                            height="19"
                                            viewBox="0 0 16 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M9.33317 11.9999V8.33325C9.33317 6.86049 8.13924 5.66659 6.6665 5.66659C5.19374 5.66659 3.99984 6.86049 3.99984 8.33325V11.9999C3.99984 14.3932 5.93994 16.3333 8.33317 16.3333C10.7264 16.3333 12.6665 14.3932 12.6665 11.9999V5.66659H13.9998V11.9999C13.9998 15.1295 11.4628 17.6666 8.33317 17.6666C5.20356 17.6666 2.6665 15.1295 2.6665 11.9999V8.33325C2.6665 6.12411 4.45736 4.33325 6.6665 4.33325C8.87564 4.33325 10.6665 6.12411 10.6665 8.33325V11.9999C10.6665 13.2886 9.62184 14.3333 8.33317 14.3333C7.0445 14.3333 5.99984 13.2886 5.99984 11.9999V8.33325H7.33317V11.9999C7.33317 12.5522 7.7809 12.9999 8.33317 12.9999C8.88544 12.9999 9.33317 12.5522 9.33317 11.9999Z"
                                              fill="#3C3C3C"
                                            />
                                          </svg>
                                        </span>
                                        <input
                                          id="file-upload"
                                          name="file-upload"
                                          type="file"
                                          className="sr-only"
                                          onChange={handleFileChange}
                                        />
                                      </label>
                                    )}
                                  </div>
                                  <button
                                    type="button"
                                    className={classNames(
                                      file ? "h-[60px]" : "h-[40px]",
                                      "relative w-[40px] bg-[#F0F7FF] -ml-px items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    )}
                                    onClick={() => addNewComment()}
                                  >
                                    <svg
                                      width="17"
                                      height="18"
                                      viewBox="0 0 17 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.916667 0.121307C0.986875 0.121307 1.05595 0.139049 1.11747 0.172882L16.5028 8.63482C16.7045 8.74574 16.7781 8.99907 16.6672 9.20074C16.6291 9.26999 16.5721 9.32699 16.5028 9.36507L1.11747 17.827C0.915833 17.9379 0.662475 17.8643 0.551575 17.6627C0.517742 17.6012 0.5 17.5322 0.5 17.4619V0.537974C0.5 0.307857 0.68655 0.121307 0.916667 0.121307ZM2.16667 2.65207V8.16657H6.33333V9.83324H2.16667V15.3478L13.7083 8.99991L2.16667 2.65207Z"
                                        fill="#3C3C3C"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      }
    </>
  );
}

export default TaskDetail;
